import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout/layout";
import Img from "gatsby-image";
import { Container, Content, Section } from "bloomer";

export default class ProjectTemplate extends React.Component {
  render() {
    const project = this.props.data.markdownRemark;

    return (
      <Layout>
        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <h1 class="title is-1">{project.frontmatter.title}</h1>
            <hr />
          </Container>
        </Section>

        {project.frontmatter.cover ?
          <Img style={{ marginBottom: '1.5rem' }} fluid={project.frontmatter.cover.childImageSharp.fluid} />
          :
          (null)
        }
        <Section>
          <Container>
            <Content renderAs="article" dangerouslySetInnerHTML={{ __html: project.html }} />
          </Container>
        </Section>
      </Layout >
    );
  }
}

export const pageQuery = graphql`
  query NewsBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      fields {
          slug
      }
      frontmatter {
          title
      }
    }
  }
`;
